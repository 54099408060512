<template>
    <div class="wrapper cursor-pointer group" v-if="video" @click="ClickAction">
        <div class="relative w-full">
            <img :src="getThumbnailUrl" loading="eager"
                class="rounded-2xl pointer-events-none group-hover:ring-4 group-hover:ring-green-600/75 transition-all h-full w-full object-cover">
            <div class="icon-wrapper absolute top-3 right-3 flex gap-2 opacity-75">
                <fa-icon v-if="video.reverse" class="bg-green-600 rounded-xl text-white p-3" icon="rotate-left" />
                <fa-icon v-if="video.exercise" class="bg-green-600 rounded-xl text-white p-3" icon="dumbbell" />
                <div v-if="video.twoTimes"
                    class="bg-green-600 text-white font-bold text-sm rounded-xl aspect-square h-10 flex justify-center items-center">
                    2X</div>
                <fa-icon v-if="getIsFavorite" class="bg-white rounded-xl text-red-600 p-3" icon="heart" />
            </div>
        </div>
        <div class="mt-2.5">
            <p class="font-semibold text-base">{{ video.name }}</p>
            <p class="text-sm text-neutral-500">{{ video.subtitle }}</p>
        </div>
    </div>
</template>
<script>

export default {
    props: ['video', "category_id"],
    computed: {
        getThumbnailUrl() {
            let url = this.video.thumbnail
            return 'https://media.taichivoorthuis.nl/' + url
        },
        constructVideoUrl() {
            return '/play/' + this.video.category + '/' + this.video.id

        },
        getIsFavorite() {
            let favorite = this.video.category + '/' + this.video.id
            let userData = this.$store.getters.getUserData
            let savedVideos = userData.savedVideos

            return savedVideos.some(el => el === favorite) ? true : false
        }

    },
    methods: {
        ClickAction() {
            this.$router.push(this.constructVideoUrl)
        }
    },
}
</script>